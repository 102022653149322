<template>
  <ValidationObserver ref="validationObserver" v-slot="{ invalid, handleSubmit }">

    <b-modal
      id="modal-update-model"
      centered
      size="lg"
      title="Update Model Details"
      ok-title="Save Changes"
      ok-variant="success"
      :ok-disabled="invalid"
      cancel-title="Discard"
      cancel-variant="outline-secondary"
      @ok="onSubmit"
      @shown="reset"
    >

      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group>
          <LabelWithHoverIcon label-string="Model name" popover-text="Only the model owner can change this value." />
          <b-form-group
            label-for="v-model-name"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="modelName"
              name="Model name"
              rules="required|min:2"
            >
              <b-form-input
                id="v-model-name"
                v-model="name"
                :disabled="$store.state.model.owner !== $store.state.auth.id"
                placeholder="Model name"
                :state="errors.length > 0 ? false:null"
                debounce="275"
                @update="validate"
                @focusout="validate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <b-form-group>
          <LabelWithHoverIcon label-string="Description" />
          <b-form-textarea
            id="model-description"
            v-model="description"
            placeholder="Describe the model"
            rows="6"
            required
          />
        </b-form-group>

        <b-form-group>
          <LabelWithHoverIcon label-string="Scope" />
          <b-form-textarea
            id="model-scope"
            v-model="scope"
            placeholder="Define the scope of the project"
            rows="6"
            required
          />
        </b-form-group>
        <b-form-group>
          <LabelWithHoverIcon
            label-string="Security classification"
            popover-text="The default security classification that will be used for all entities within this model. This can be overriden on individual entities."
          />

          <b-form-select
            v-model="classification"
            :options="security_classification_options"
          />
        </b-form-group>

        <b-form-group @submit.prevent="handleSubmit(onSubmit)">
          <LabelWithHoverIcon label-string="Shared with" />
          <b-list-group v-if="shared.length" id="model-shared">
            <b-list-group-item
              v-for="(user, index) in shared"
              :key="index"
              class="d-flex justify-content-between align-items-center py-25"
            >
              {{ getUserUsername(user) }}
              <b-button
                class="ml-auto"
                size="sm"
                variant="flat-danger"
                @click="shared.splice(index, 1)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="14"
                />
              </b-button>
            </b-list-group-item>
          </b-list-group>
          <p v-else class="ml-50 text-muted">
            Nobody
          </p>
        </b-form-group>
      </b-form>
    </b-modal>
  </ValidationObserver>

</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import coreService from '@/libs/api-services/core-service'
import { ref } from '@vue/composition-api'

export default {
  name: 'UpdateModel',
  components: {
    ValidationProvider,
    ValidationObserver,
    // Permissions,
    LabelWithHoverIcon,
  },
  setup() {
    const validationObserver = ref(null)
    return { validationObserver }
  },
  data() {
    return {
      isOwnerEdit: false,
      owner: '',
      isPreviousOwnerShared: true,
      classification: this.$store.state.model.defaultSecurityClassification,
      name: this.$store.state.model.name,
      description: this.$store.state.model.description,
      scope: this.$store.state.model.scope,
    }
  },
  computed: {
    ...mapGetters({
      security_classification_options: 'constants/securityClassifications',
    }),
    shared() {
      return this.$store.state.model.shared_with
    },
  },
  methods: {
    validate() {
      if (this.name !== this.$store.state.model.name) {
        this.$refs.validationObserver
          .validate()
          .then(isValid => {
            coreService.modelApi.validateModelName(this.name)
              .then(data => {
                this.$refs.validationObserver.setErrors(data.errors)
              })
              .catch(e => console.error(e))
          })
      }
    },
    onSubmit() {
      const params = {
        model_id: this.$store.state.model.id,
        name: this.name ? this.name : this.$store.state.model.name,
        description: this.description,
        scope: this.scope,
        default_security_classification: this.classification,
        shared_with: this.shared,
      }
      this.$http.post('/api/v2/models/update', params)
        .then(() => {
          this.$store.commit('model/updateModelName', params.name)
          this.$store.commit('model/updateModelDescription', params.description)
          this.$store.commit('model/updateModelScope', params.scope)
          this.$store.commit('model/updateModelShared', params.shared_with)
          this.$store.commit('model/updateModelDefaultSecurityClassification', params.default_security_classification)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(response => {
          console.error(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update model',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeOwner() {
      const payload = {
        model: this.$store.state.model.id,
        new_owner: this.owner,
        share_with_current_owner: this.isPreviousOwnerShared,
      }
      this.$http
        .patch('/api/v2/models/change_ownership', payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model Ownership Changed',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({
            name: 'model_browse',
          })
          this.$store.dispatch('model/closeWorkspace')
          this.$bvModal.hide('modal-update-model')
        })
        .catch(e => {
          console.error(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to change ownership',
              text: `An error occurred when attempting to change ownership.
              Server returned Status ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    reset() {
      this.name = this.$store.state.model.name
      this.isOwnerEdit = false
      this.owner = this.getUserUsername(this.$store.state.model.owner)
      this.classification = this.$store.state.model.defaultSecurityClassification
    },
  },
}
</script>
